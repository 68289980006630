import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    fetchQueue,
    resetSearch,
    fetchLocations,
    searchPatients,
    getChartOpenBy,
    checkChartOpenBy,
    verifyPatient,
    createQuickWorkItem,
    redirectToRecords,
    unDeclinePatient,
    refetchSearchPatients,
    requestChartClosure,
    openEventStream,
    loadPatientDetail,
    getPHSPdf,
    checkIfChartClosed,
    checkOpenAssociatedChart,
    resetSearchResult,
    getPatientNotes,
    getClinicians,
    getAllClinicians,
    getMyQueueCounts,
    getMyTeamQueueCounts,
    getAllPatientsQueueCounts,
    getUrgentTodoTypes,
    getPatientServicesReasons
} from '../../actions/admin';
import { isAdmin,isMutiTeamUser,isTriage ,isMA} from '../../utils';
import { Panel, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { Table, Column, Cell } from 'fixed-data-table';
import SelectPatientConfirmPopup from './SelectPatientConfirmPopup';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import PaginationBar from '../../components/PaginationBar'
import Filter from '../../components/Filter';
import MultiSelectFilter from "../../components/MultiselectFilter"
import PageHeader from '../../components/PageHeader';
import PatientCell from './PatientCell';
import PatientWithStatusCell from './PatientWithStatusCell'
import LastWorkedCell from './LastWorkedCell';
import LastCalledCell from './LastCalledCell';
import ActionCell from './ActionCell';
import AddressCell from './AddressCell';
import LastContactCell from './LastContactCell';
import TimeBilledCell from './TimeBilledCell';
import MyPatientBilledCell from './MyPatientBilledCell'
import StatusCell from './StatusCell';
import MyPatientStatusCell from './MyPatientStatusCell';
import SearchHeader from './SearchHeader';
import PatientDetailCell from './PatientDetailCell';
import PatientNewRecordDataCell from './PatientNewRecordDataCell';
import ProviderDetailCell from './ProviderDetailCell';
import ProgramDetailCell from './ProgramDetailCell'
import APCMProgramDetailCell from './APCMProgramDetailCell'
import StatusActionsColumn from './StatusActionsColumn';
import OptOutModal from './OptOutModal';
import PatientNotesPopup from './PatientNotesPopup'
import UnDeclineModal from './UnDeclineModal';
import PatientChartClosureRequestedDataModal from '../admin/PatientChartClosureRequestedDataModal';
import { hideToast, showToast } from "../../actions/common";
import SortHeaderCell from './SortHeaderCell';
import { isAutoDial } from '../../utils';
import _ from 'lodash';
import Switch from "react-switch";


class PatientQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPatient: {},
            showPatientPopup: false,
            showOptOut: false,
            showUnDecline: false,
            showPatientDataModal: false,
            requestedClosurePatientId: null,
            columnWidths: {
                patientName: 240,
                patientDetail: 220,
                address: 220,
                phone: 220,
                dob: 220,
                actions: 180,
                lastWorkedBy: 180,
                lastContact: 180,
                lastOutCall: 180,
                callAttempts: 80,
                provider: 220,
                program: 220,
                providerDetail: 240,
                timeBilled: 200,
                todoItem: 350,
                status: 200,
                chart: 150,
                contactPreference: 260
            },
            colSortDirs: {},
            notes:null,
            completedChecked:true,
            apcmEnrollment:null,
            showOnlyCallAttempts: false
        };
    }

    componentDidMount(){
        const {urgentTypes,showUrgentFilter, queue} = this.props;
        if(!urgentTypes && showUrgentFilter){
            this.props.getUrgentTodoTypes();
        }
        if(queue === 'allPatients'){
            this.props.getAllClinicians("","1");
        }
        if(!this.props.patientServicesReasons){
            this.props.getPatientServicesReasons();
        }
    }

    componentWillMount() {
        const { router } = this.props;
        if (router.location.pathname.indexOf("search") !== -1) {
            //reset previous result
            this.props.resetSearchResult();
            const phoneNumber = router.location.query.phone;
            // console.log("phoneNumber:",phoneNumber);
            const email = router.location.query.email;
            const id = router.location.query.id;
            this.searchPhone = phoneNumber ? phoneNumber + '' : '';
            if (phoneNumber) {
                let phone = phoneNumber.trim().replace(/\D/g, '');
                if (phone && phone.length > 10) {
                    phone = phone.substring(phone.length - 10);
                }
                this.handleSearch(null, null, phone);
            }else if(email && email.trim()){
                  this.handleSearch(null, null, null,null,email);
                  this.email = email;
            }else if(id && id.trim()){
                this.handleSearch(null, null, null,id,null);
                this.id = id;
          }

        } else {
            this.checkCssRedirect();
            if(this.props.queue == 'myPatients'){
                this.props.getMyQueueCounts('');
            }
            if(this.props.queue == 'myTeamPatients'){
                this.props.getMyTeamQueueCounts('')
            }
            if(this.props.queue == 'allPatients'){
                this.props.getAllPatientsQueueCounts('')
            }
            if (this.props.activeUser != undefined) {
                this.loadPatients(this.props);
            }
        }

    }

    componentWillReceiveProps(nextProps,nextState) {
        const { router } = this.props;
        if (router.location.pathname.indexOf("search") === -1) {
            if (nextProps.queue !== this.props.queue) {
                this.loadPatients(nextProps);
            }
            if (nextProps.orgId && nextProps.orgId !== this.props.orgId) {
                nextProps.fetchLocations(nextProps.orgId);
            }
            if (nextProps.activeUser !== this.props.activeUser) {
                this.loadPatients(nextProps);
            }
            if (nextProps.chartClosureRequested && (nextProps.chartClosureRequested.showPatientDataModal !== this.state.showPatientDataModal)) {
                this.setState({ 'showPatientDataModal': nextProps.chartClosureRequested.showPatientDataModal });
            }
            this.checkCssRedirect(nextProps);
        }
    }

    _onSortChange(columnKey, sortDir) {
        const {
            queue, endpoint, meta, pageSize, orgId, locationId, providerId, showFilters, showSort,
            showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,preferredLanguage,
            gapsEngagementStatus
        } = this.props;
        let sortQuery = `${columnKey}&dir=${sortDir}`;
        this.setState({
            colSortDirs: {
                [columnKey]: sortDir,
            },
        });

        this.props.fetchQueue(queue,
            endpoint,
            meta.page,
            pageSize,
            orgId,
            locationId,
            providerId,
            showFilters,
            showSort,
            sortQuery,
            showProgramTypeFilter,
            programType,
            showScheduleCallFilter,
            scheduledCallStatus,
            gapsEngagementStatus,
            preferredLanguage)

    }

    checkCssRedirect(nextProps) {
        const isUserAdmin = isAdmin(this.props.user.roles);
        if (!isUserAdmin && (this.props.queue == 'urgent' || (nextProps && nextProps.queue == 'urgent'))) {
            this.props.redirectToRecords();
        };
    }

    loadPatients = (props, pageNum = 0) => {
        if (props.queue !== 'search' && props.queue !== 'urgent' && props.queue !== '') {
            if(this.props.queue == 'myPatients'){
                this.props.getMyQueueCounts('');
            }
            if(this.props.queue == 'myTeamPatients'){
                this.props.getMyTeamQueueCounts('')
            }
            if(this.props.queue == 'allPatients'){
                this.props.getAllPatientsQueueCounts('')
            }
        
            props.fetchQueue(
                props.queue,
                props.endpoint,
                props.meta.page,
                props.pageSize,
                props.orgId,
                props.locationId,
                props.providerId,
                props.showFilters,
                props.showSort,
                props.sortBy,
                props.showProgramTypeFilter,
                props.programType,
                props.showScheduleCallFilter,
                props.scheduledCallStatus,
                props.gapsEngagementStatus,
                props.preferredLanguage,
                props.selectedTeam,
                props.selectedClinician,
                props.callAttempts,
                props.completedChecked === undefined ? (props.queue == 'myPatients' 
                || props.queue == 'myTeamPatients' || props.queue == 'allPatients') : props.completedChecked,
                props.urgentType,
                props.reengageFilter ? props.reEngageFiltering : '',
                props.showClinicalServiceGoalFilter ? props.clinicalServiceGoal: '',
                props.selectedTeamForAllPateints,
                props.selectedClinicianForAllPatients,
                props.selectedGeographics,
                props.selectedTimezones,
                props.allPatientsCallAttempts,
                props.connectedCall,
                props.showOnlyCallAttempts === undefined ? (props.queue == 'records') : props.showOnlyCallAttempts,
                props.showUrgentFilter,
                props.apcmEnrollment,
                props.selectedEligibilityLevel
            );
        } else {
            props.resetSearch()
        }
    }

    handleColumnResize = (columnWidth, columnKey) => {
        this.setState({
            columnWidths: {
                ...this.state.columnWidths,
                [columnKey]: columnWidth,
            },
        });
    }

    preventAccess = (charts) => {
        let result_array = charts['result'];
        for (let i = 0; i < result_array.length; i++) {
            if (result_array[i].chart_open) {
                return true
            }
        }
    }

    handlePatientSelect = (patient) => {
        if (this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.ce_flag) {
            this.props.checkOpenAssociatedChart(patient.id)
                .then((result) => {
                    const preventAccess = this.preventAccess(result);
                    if (preventAccess) {
                        this.props.showToast({
                            status: 'error',
                            title: 'Denied',
                            message: 'An associated chart is currently open.',
                        });
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            this.props.hideToast();
                        }, 2);
                    } else {
                        this.props.loadPatientDetail(patient);
                    }
                })
                .catch((e) => {
                    if (e.code !== 'session_timeout') {
                        this.props.showToast({
                            status: 'error',
                            title: 'Failure',
                            message: 'Failed to check associated charts.',
                        });
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            this.props.hideToast();
                        }, 2);
                    }

                });
        } else {
            window.localStorage.setItem('ccm_queue', this.props.queue === 'search' ? 'search' : this.props.currentQueue);
            window.localStorage.setItem('ccm_queue_user', this.props.activeUser.username);
            this.props.loadPatientDetail(patient);
        }
    }

    handleShowOptOut = (patient) => {
        this.setState({
            showOptOut: true,
            selectedPatient: patient,
        });
    }

    handleShowUndecline = (patient) => {
        this.setState({
            showUnDecline: true,
            selectedPatient: patient,
        });
    }

    handleUnDecline = () => {
        this.props.unDeclinePatient(this.state.selectedPatient.id)
            .then(() => {
                this.props.refetchSearchPatients(this.state.searchLastName, this.state.searchDate, this.state.searchPhone, this.state.searchCCMId);
                this.setState({ showUnDecline: false });
            });
    }

    handleSearch = (lastName, date, phone, ccmId,email) => {
        this.setState({
            searchLastName: lastName,
            searchDate: date,
            searchPhone: phone,
            searchCCMId: ccmId,
            searchEmail:email
        })
        this.props.searchPatients(lastName, date, phone, ccmId,email);
    }

    handleOptOut = () => {
        this.props.adminActions.optOutPatient(this.state.selectedPatient.id)
            .then(() => {
                this.loadPatients(this.props, this.props.meta.page);
                this.setState({ showOptOut: false });
            });
    }

    handleVerifyPatient = (patient) => {
        this.props.adminActions.verifyPatient(patient)
            .then(() => {
                this.loadPatients(this.props, this.props.meta.page);
            });
    }

    popoverHoverFocus(description) {
        if(!description){
            return <div/>;
        }
        return (
            <Popover id="popover-trigger-hover-focus">
                <span>{description}</span>
            </Popover>
        )
    }

    onShowPhs = (patient) => {
        console.log(patient);
        this.props.getPHSPdf(patient.id, hasError => {
            if (!hasError) {
                window.open(this.props.phsPdfUrl, '_blank').focus();
            }
        });
    };

    handleRequestChartClosure = (openChart) => {
        this.props.checkChartOpenBy(openChart.id)
            .then((chartOpenedBy) => {
                const chartObject = {
                    ma_id: chartOpenedBy.id,
                    active_patient_id: openChart.id
                };
                this.props.requestChartClosure(chartObject);
                this.setState({ requestedClosurePatientId: openChart.id });
            })
            .catch(() => {
                this.props.checkIfChartClosed(openChart.id);
            });
        // this.setState({ openChart });
    };

    onShowNotes = (patientId) => {
        this.props.getPatientNotes(patientId)
            .then(result => {
                console.log("result",result);
                this.setState({notes:result.notes});
            });
    }

    inAutoDialValue = (patient) => {
        const latest_checkout_ts = patient ? patient.latest_checkout_ts : "";
        const latest_checkin_ts = patient ? patient.latest_checkin_ts : "";
        const inAutoDial = isAutoDial(latest_checkout_ts,latest_checkin_ts);
        return inAutoDial;
    }


    getMyPatientStatusColor = (patient) => {
        const {queue} = this.props;
        if(queue !== 'myPatients' && queue !== 'myTeamPatients' && queue !== 'allPatients'){
            return {}
        }

       const { urgent_flag, status_description, status, suspended,provider_transition,
            current_est_time,completed,reconsent,provider_transition_effective_date,apcm_enrollment} = patient;
        const transitionEffectiveDate = moment(provider_transition_effective_date);
        const currentESTTime = moment(current_est_time);
        const providerTransition = currentESTTime.isAfter(transitionEffectiveDate) && provider_transition;
        const completedColor = 'rgba(214,220,210,1)';
        const reconsentColor = '#ffe6b4';
        const suspendedColor = '#FFCAC9';
        let borderRightStyle = '1px solid #9D9D9D'
        let apcmConsent = false;
        if(apcm_enrollment == 'Candidate'){
            apcmConsent = true;
        }

        let applicableBackground = '#FFFFFF';
        if(suspended || providerTransition){
            applicableBackground = suspendedColor;
            borderRightStyle = '2px solid #9D9D9D'
        }else if(apcmConsent || reconsent){
            applicableBackground = reconsentColor;
            borderRightStyle = '2px solid #9D9D9D'
        }else if(completed){
            applicableBackground = completedColor;   
            borderRightStyle = '2px solid #9D9D9D'
        }
        
        return {background:applicableBackground,border:'0.5px solid #9D9D9D'}
    }

    getReason(reasonId){
        if(!this.props.patientServicesReasons) return "";
        const reasonObject = _.find(this.props.patientServicesReasons,(reason) => reason.id == reasonId);
        return reasonObject ? reasonObject.reason : "-";
    }

    render() {
        const { columnWidths, colSortDirs } = this.state;
        let {
            queue,
            endpoint,
            label,
            meta,
            pageSize,
            callLogCodes,
            patient,
            patients,
            organizations,
            orgId,
            locations,
            locationId,
            providers,
            providerId,
            showFilters,
            showSearch,
            showQueueCount,
            rowHeight,
            columnHeaderStyle,
            patientNameColumn,
            addressColumn,
            phoneColumn,
            dobColumn,
            actionsColumn,
            lastContactColumn,
            lastWorkedByColumn,
            callAttempts,
            lastOutCallColumn,
            sortOptions,
            openedByColumn,
            showSort,
            sortBy,
            timeBilledColumn,
            todoItemColumn,
            supportDetailColumn,
            lastWorkedHistoryColumn,
            statusColumn,
            chartColumn,
            openCloseChartColumn,
            patientDetailColumn,
            providerDetailColumn,
            programTypeColumn,
            apcmprogramTypeColumn,
            statusActionsColumn,
            lastHistoryColumn,
            noSearchResults,
            activeUser,
            user,
            showProgramTypeFilter,
            programType,
            showScheduleCallFilter,
            scheduledCallStatus,
            gapsEngagementStatus,
            preferredLanguage,
            teams,
            clinicians,
            allClinicians,
            selectedTeam,
            selectedTeamForAllPateints,
            selectedClinician,
            selectedClinicianForAllPatients,
            adminActions,
            callAttemptsCol,
            patientNameWithStatusColumn,
            myPatientBillingColumn,
            myPatientStatusColumn,
            completedChecked,
            counts,
            showUrgentFilter,
            urgentType,
            reengageFilter,
		    reengageIndicator,
            reEngageFiltering,
            hideAssignee,
            showClinicalServiceGoalFilter,
            clinicalServiceGoal,
            selectedGeographics,
            selectedTimezones,
            allPatientsCallAttempts,
            connectedCall,
            showSupportReason,
            patientServicesReasons,
            patientNewRecordColumn,
            recordInformation,
            showOnlyCallAttempts,
            apcmEnrollment,
            showEligibilityFilter,
            selectedEligibilityLevel
        } = this.props;
        const isUserAdmin = isAdmin(user.roles);
        const isMultiTeamUserRole = isMutiTeamUser(user.roles);
        const isTriageUser = isTriage(user.roles);
        const isMAUser = isMA(user.roles);
        if(!activeUser){
            return null;
        }
        // if(queue === 'allPatients'){
        //     selectedTeam = selectedTeamForAllPateints;
        //     selectedClinician = selectedClinicianForAllPatients;
        // }
        let isMyPatientQueue = queue == 'myPatients' || queue == 'myTeamPatients' || queue == 'allPatients';
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const teamId = activeUser && activeUser.team_id;
        let ce_flag, isRetSpec;
        if (activeUser && activeUser.center && activeUser.center.company_id) {
            ce_flag = activeUser.center.ce_flag;
            isRetSpec = activeUser.center.support_flag;
        }
        let showTeamFilter, showClinicianFilter = false;
        let showAttemptFilter = false;
        let completedCount = ""-";"
        let assignedCount = ""-";"
        const completedCounts = _.filter(counts,(countData) => {
            return countData.key == "completed"
        })[0];
        const assignedCounts = _.filter(counts,(countData) => {
            return countData.key == "assigned"
        })[0];
        completedCount = completedCounts ? completedCounts.value : "0"
        assignedCount = assignedCounts ? assignedCounts.value : "0"
        if(!isGAPS && !isRPM && !ce_flag  && !isRetSpec){
            if(callAttemptsCol || queue == 'myPatients' || queue == 'myTeamPatients' || queue == 'allPatients'){
                showAttemptFilter = true;
            }
            if(isUserAdmin || isMultiTeamUserRole || isTriageUser){
                 showTeamFilter = true;
                 showClinicianFilter = true;
            }else{
                showTeamFilter = false;
                if(teamId && teamId != 1){
                    showClinicianFilter = true;
                }else{
                    showClinicianFilter = false;
                }
                
            }
        }
        if(queue == 'myPatients'){
            showTeamFilter = false;
            showClinicianFilter = false;
        }else if(queue == 'myTeamPatients'){
            showTeamFilter = false;
            showClinicianFilter = true;
        }else if(queue === 'allPatients'){
            showTeamFilter = true;
            showClinicianFilter = true;
        }

        const declineButtonText = isCne ? 'Decline' : 'Opt Out';

        let loadNoteNeeded = false;
        if(!isCne && !isGAPS && !isRPMUser && !isRetSpec && !isMAUser ){
            loadNoteNeeded = true;
        }

        

        return (
            <div>
                <div style={{ display: 'flex',  marginBottom: '1rem',justifyContent:'flex-end' }}>
                    <PageHeader>
                        
                    </PageHeader>
                    {
                        queue !== 'myPatients' && queue !==  'myTeamPatients' &&  queue !== 'allPatients' &&
                        <div style={{ display:'flex',flex:1,alignItems:'center' }}>
                                {
                                    showFilters && label && <span>
                                        <i style={{fontSize:28,color:'#337ab7'}} className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                    </span>
                                }
                                
                            </div>
                    }
                   
                    {showFilters &&
                        <div style={{ display: 'flex',flexWrap:'wrap',paddingLeft:15 }}>
                            {
                                showProgramTypeFilter && !isCne && !isRPM &&
                                <Filter
                                    label="Program Type"
                                    options={[{
                                        id: 'ccm',
                                        name: 'CCM'
                                    },
                                    {
                                        id: 'pathways_stroke_care',
                                        name: 'Pathways Stroke Care'
                                    }]}
                                    value={programType}
                                    onChange={(newProgramType) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, newProgramType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,false,urgentType,  reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall)
                                    }
                                />
                            }

                            {
                                showTeamFilter && queue !== 'allPatients' && <Filter
                                label="Team"
                                options={teams}
                                value={selectedTeam}x
                                onChange={(newTeam) => {
                                    this.props.getClinicians(user.id,newTeam);
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        newTeam, '' ,callAttempts,false,urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall);
                                    }
                                }
                            />
                            }

                            {
                                showTeamFilter && queue === 'allPatients' && 
                                <MultiSelectFilter
                                label="Team"
                                options={teams}
                                value={selectedTeamForAllPateints}
                                onChange={(newTeamList) => {
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        newTeamList, selectedClinician,callAttempts,completedChecked,urgentType,reengageFilter ? reEngageFiltering : '','',newTeamList,selectedClinicianForAllPatients,
                                        selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                    }
                                }
                            />
                            }

                            

                            {
                                showClinicianFilter && queue !== 'allPatients' &&  <Filter
                                disabled={(!(queue == 'myTeamPatients' || queue == 'allPatients')) && (!selectedTeam || selectedTeam == 1)}
                                label="Clinician"
                                options={queue !== 'allPatients' ? clinicians : allClinicians}
                                value={selectedClinician}
                                onChange={(newClinician) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        selectedTeam,newClinician,callAttempts,completedChecked,urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                }
                            />
                            }

                            {
                                showClinicianFilter && queue === 'allPatients' && 
                                <MultiSelectFilter
                                label="Clinician"
                                options={allClinicians}
                                value={selectedClinicianForAllPatients}
                                onChange={(newClinicianList) => {
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        selectedTeam, selectedClinician,callAttempts,completedChecked, urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,newClinicianList,selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment);
                                    }
                                }
                            />
                            }

                            {
                                showClinicianFilter && queue === 'allPatients' && 
                                <MultiSelectFilter
                                label="State"
                                options={[{name:"California",id:"California"},
                                        {name:"Pennsylvania",id:"Pennsylvania"},
                                        {name:"Oregon",id:"Oregon"},
                                        {name:"Maryland",id:"Maryland"},
                                        {name:"Connecticut",id:"Connecticut"},
                                        {name:"New Jersey",id:"New Jersey"},
                                        {name:"Unknown",id:"Unknown"},
                                        {name:"All Others",id:"All Others"},
                                ]}
                                value={selectedGeographics}
                                onChange={(newGeographics) => {
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        selectedTeam, selectedClinician,callAttempts,completedChecked, urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients,newGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment);
                                    }
                                }
                            />
                            }

                            {
                                showClinicianFilter && queue === 'allPatients' && 
                                <MultiSelectFilter
                                label="Time Zone"
                                options={[{name:"US/Eastern",id:"US/Eastern"},
                                        {name:"US/Central",id:"US/Central"},
                                        {name:"US/Mountain",id:"US/Mountain"},
                                        {name:"US/Pacific",id:"US/Pacific"},
                                        {name:"US/Hawaii",id:"US/Hawaii"}
                                        ]}
                                value={selectedTimezones}
                                onChange={(newTimezones) => {
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,preferredLanguage,
                                        selectedTeam, selectedClinician,callAttempts,completedChecked, urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients,selectedGeographics,newTimezones, allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment);
                                    }
                                }
                            />
                            }

                            { showAttemptFilter &&  queue === 'allPatients' &&
                                <MultiSelectFilter
                                    label="Call Attempts"
                                    options={[{
                                        id: '0',
                                        name: 'No Call'
                                    },
                                        {
                                            id: '1',
                                            name: '1 Call'
                                        },
                                        {
                                            id: '2',
                                            name: '2 Calls'
                                        },
                                        {
                                            id: '2+',
                                            name: '3+ Calls'
                                        }]}
                                    value={allPatientsCallAttempts}
                                    onChange={(newAttempts) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients,selectedGeographics,selectedTimezones,newAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                    }
                                />
                            }
                            {
                                showEligibilityFilter && queue === 'apcmunverified' && 
                                <Filter
                                    label="APCM Eligible Level"
                                    options={[
                                        {
                                            id: '1',
                                            name: 'Level 1'
                                        },
                                        {
                                            id: '2',
                                            name: 'Level 2'
                                        },
                                        {
                                            id: '3',
                                            name: 'Level 3'
                                        }
                                        ]}
                                    value={selectedEligibilityLevel }
                                    onChange={(newEligibilityLevel) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment, newEligibilityLevel)
                                    }
                                />
                            }

                            {
                                showScheduleCallFilter  && !isGAPS && !isRPM &&
                                <Filter
                                    label="Scheduled Call"
                                    options={[
                                        {
                                            id: 'today',
                                            name: 'Today'
                                        },
                                        {
                                            id: 'tomorrow',
                                            name: 'Tomorrow'
                                        },
                                        {
                                            id: 'all_scheduled',
                                            name: 'All Scheduled'
                                        },
                                        {
                                            id: 'all_no_call_preference',
                                            name: 'No Call Preference'
                                        },
                                        {
                                            id: 'all_non_scheduled',
                                            name: 'No Call Scheduled'
                                        }
                                        ]}
                                    value={scheduledCallStatus == null && isCne ? '' : scheduledCallStatus == null ? 'today':scheduledCallStatus }
                                    onChange={(newScheduledCallStatus) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,newScheduledCallStatus,gapsEngagementStatus,
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                    }
                                />
                            }
                            {
                                isGAPS && <Filter
                                label="Engagement Status"
                                options={[
                                    {
                                        id: 'Open',
                                        name: 'Open'
                                    },
                                    {
                                        id: 'ClosedOutOrCompleted',
                                        name: 'Closed Out / Completed'
                                    },
                                    {
                                        id: 'All',
                                        name: 'All Engaged'
                                    }
                                ]}
                                value={gapsEngagementStatus == null && isGAPS ? 'Open' : gapsEngagementStatus }
                                onChange={(newgapsEngagementStatus) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,newgapsEngagementStatus,preferredLanguage,
                                        selectedTeam,selectedClinician,callAttempts,false,urgentType,reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall)
                                }
                            />
                            }
                            <Filter
                                label="Language"
                                options={[{
                                    id: 'English',
                                    name: 'English'
                                },
                                    {
                                        id: 'Spanish',
                                        name: 'Spanish'
                                    },
                                    {
                                        id: 'Other',
                                        name: 'Other'
                                    }]}
                                value={preferredLanguage}
                                onChange={(newPreferredLanguage) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                        newPreferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                }
                            />
                            
                            {
                                !isGAPS && <Filter
                                    label="Organization"
                                    options={organizations}
                                    value={orgId}
                                    onChange={(newOrgId) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, newOrgId, null, null, showFilters,
                                            showSort, sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType,reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                    }
                                />
                            }

                            {
                                !isGAPS && <Filter
                                    label="Location"
                                    options={locations}
                                    value={locationId}
                                    onChange={(newLocationId) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, newLocationId, null, showFilters,
                                            showSort, sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,
                                            gapsEngagementStatus,preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType,reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                    }
                                />
                            }
                            {
                                !isGAPS && <Filter
                                    label="Provider"
                                    options={providers}
                                    value={providerId}
                                    onChange={(newProviderId) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, newProviderId, showFilters,
                                            showSort, sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,
                                            gapsEngagementStatus,preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                    }
                                />
                            }
                            {
                                reengageFilter &&  <Filter
                                label="Re-engage"
                                options={[{
                                    id: 'NewUnverified',
                                    name: 'New unverified only'
                                },
                                {
                                    id: 'ReEngaged',
                                    name: 'Re-engage only'
                                }]}
                                value={reEngageFiltering}
                                onChange={(newReEngageFiltering) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                        preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType,newReEngageFiltering,
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment,selectedEligibilityLevel)
                                }
                            />
                            }

                            { showAttemptFilter &&  queue !== 'allPatients' &&
                                <Filter
                                    label="Attempts"
                                    options={[{
                                        id: '0',
                                        name: 'No Call'
                                    },
                                        {
                                            id: '1',
                                            name: '1 Call'
                                        },
                                        {
                                            id: '2',
                                            name: '2 Calls'
                                        },
                                        {
                                            id: '2+',
                                            name: '3+ Calls'
                                        }]}
                                    value={callAttempts}
                                    onChange={(newAttempts) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                            preferredLanguage,selectedTeam,selectedClinician,newAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                            showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                    }
                                />
                            }
                           

                            {
                                showUrgentFilter && <Filter
                                label="Urgent"
                                options={_.map(this.props.urgentTypes,(type) => {
                                    return {
                                        id:type.id,
                                        name:type.name
                                    }
                                })}
                                value={urgentType}
                                onChange={(newUrgentType) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                        preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,newUrgentType, reengageFilter ? reEngageFiltering : '','',
                                        selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,showOnlyCallAttempts,
                                        showUrgentFilter,apcmEnrollment)
                                }
                            />
                            }
                              {
                                showClinicalServiceGoalFilter && <Filter
                                label="Clinical Service Goal"
                                options={[{
                                    id: '20',
                                    name: '20 Minutes'
                                },
                                    {
                                        id: '40',
                                        name: '40 Minutes'
                                    },
                                    {
                                        id: '60',
                                        name: '60 Minutes'
                                    }]}
                                value={clinicalServiceGoal}
                                onChange={(newClinicalServiceGoal) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                        preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                        newClinicalServiceGoal, selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                }
                            />
                            }
                            {
                                isMyPatientQueue &&  <Filter
                                label="APCM Enrollment"
                                options={[{
                                    id: 'Candidate',
                                    name: 'Candidate'
                                },
                                    {
                                        id: 'NonCandidate',
                                        name: 'Non Candidate'
                                    }]}
                                value={apcmEnrollment}
                                onChange={(newApcmEnrollment) =>
                                    this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                        sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus, 
                                        preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                        clinicalServiceGoal, selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                        showOnlyCallAttempts,showUrgentFilter,newApcmEnrollment)
                                }
                            />
                            }     
                               

                        </div>
                    }

                    {
                                showProgramTypeFilter && !isCne && !isRPM &&
                                <Filter
                                    label="Program Type"
                                    options={[{
                                        id: 'ccm',
                                        name: 'CCM Only'
                                    },
                                    {
                                        id: 'apcm',
                                        name: 'APCM Only'
                                    }]}
                                    value={programType}
                                    onChange={(newProgramType) =>
                                        this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                            sortBy, showProgramTypeFilter, newProgramType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                            preferredLanguage,selectedTeam,selectedClinician,callAttempts,false,urgentType,  reengageFilter ? reEngageFiltering : '','',
                                            selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall)
                                    }
                                />
                            }
                </div>
                <Panel
                    header={showSearch
                        ? <SearchHeader
                            onSearch={this.handleSearch}
                            searchPhone={this.searchPhone}
                            searchEmail={this.email}
                            searchId={this.id}
                        />
                        : <div className="clearfix">
                            <span style={{fontSize:20,fontWeight:queue !== 'myPatients' && (queue !== 'myTeamPatients' && queue !== 'allPatients') ? 500 : 600}}>{label}
                        {showQueueCount && meta.total_items >= 0 &&
                            ` - ${meta.total_items}`
                        }</span>
                        {
                            queue == 'records' &&
                            <div style={{paddingLeft:30,display:'inline-block'}}>
                                    <Switch
                                        onColor="#337ab7"
                                        onChange={
                                            () => {
                                                this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                                sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                                preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                                clinicalServiceGoal, selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                                !showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                            }
                                        }
                                        checked={showOnlyCallAttempts}
                                        className="react-switch"
                                        /> 
                                    <span style={{paddingTop:5,paddingLeft:5}}>Show Only Pateints with 1+ Call Attempts</span>
                                </div>
                        }
                        {
                            (queue == 'myPatients' || queue == 'myTeamPatients' || queue === 'allPatients') &&  
                            <div style={{display:'inline-block'}}>
                                {
                                    !hideAssignee && <span style={{marginLeft:15,padding:4,borderRadius:25,
                                        background:'rgb(118,118,118)',
                                        color:'#FFFFFF', width:'90%'}}>
                                        <i className="fa fa-user" style={{margin:'0 8px'}} aria-hidden="true"></i>
                                        <span style={{paddingRight:10}}>
                                            {assignedCount} Assigned
                                        </span>
                                    </span>
                                }
                                
                                <span style={{marginLeft:15,padding:5,borderRadius:25,background:'rgb(118,118,118)',
                                    color:'#FFFFFF'}}>
                                <i className="fa fa-check" style={{margin:'0 8px'}} aria-hidden="true"></i>
                                <span style={{paddingRight:10}}>{completedCount} Completed</span></span>
                                <div style={{paddingLeft:30,display:'inline-block'}}>
                                    <Switch
                                        onColor="#337ab7"
                                        onChange={
                                            () => {
                                                this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                                sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                                preferredLanguage,selectedTeam,selectedClinician,callAttempts,!completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                                clinicalServiceGoal, selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,
                                                showOnlyCallAttempts,showUrgentFilter,apcmEnrollment)
                                            }
                                        }
                                        checked={completedChecked}
                                        className="react-switch"
                                        /> 
                                    <span style={{paddingTop:5,paddingLeft:5}}>Show Completed</span>
                                </div>
                                
                                {
                                     queue === 'allPatients' && <div style={{paddingLeft:30,display:'inline-block'}}>
                                     <Switch
                                         onColor="#337ab7"
                                         onChange={
                                             () => {
                                                 this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort,
                                                 sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,gapsEngagementStatus,
                                                 preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                                 clinicalServiceGoal, selectedTeamForAllPateints,selectedClinicianForAllPatients, selectedGeographics,selectedTimezones,allPatientsCallAttempts,!connectedCall,showOnlyCallAttempts,
                                                 showUrgentFilter, apcmEnrollment)
                                             }
                                         }
                                         checked={connectedCall}
                                         className="react-switch"
                                         /> 
                                     <span style={{paddingTop:5, paddingLeft:5}}>Connected Call</span>
                                 </div>
                                }
                                </div>
                        }
                       
                        
                            <div className="pull-right" style={{paddingTop:isMyPatientQueue ? 5 : 'unset'}}>
                                {isMyPatientQueue && <span style={{fontStyle:'italic'}}>Patients in filtered list: 
                                    <span style={{paddingLeft:15,fontWeight:600}}>
                                    {meta.total_items}</span></span> }
                                {isMyPatientQueue ? <span style={{fontStyle:'italic',marginLeft:60}}>Refresh</span> : 'Queue'}
                                <Button
                                    bsSize="xsmall"
                                    bsStyle="primary"
                                    style={{ marginLeft: 15 }}
                                    onClick={() => this.loadPatients(this.props)}
                                >
                                    <i className="fa fa-refresh" title="Refresh" />
                                </Button>
                            </div>
                            {/*{sortOptions &&*/}
                            {/*<div className="pull-right">*/}
                            {/*Sort By:*/}
                            {/*<Sort*/}
                            {/*options={sortOptions}*/}
                            {/*value={sortBy}*/}
                            {/*onChange={(newSort) =>*/}
                            {/*this.props.fetchQueue(queue, endpoint, meta.page, pageSize, orgId, locationId, providerId, showFilters, showSort, newSort)*/}
                            {/*}*/}
                            {/*/>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </div>
                    }
                >
                    <div style={{ height: '750px', position: 'relative' }}>
                        {noSearchResults &&
                            <div className="no-search-results">
                                No Patients Found
                        </div>
                        }
                        <ResponsiveFixedDataTable
                            height={750}
                            width={1000}
                            rowHeight={rowHeight || 115}
                            headerHeight={50}
                            isColumnResizing={false}
                            rowsCount={patients.length}
                            onColumnResizeEndCallback={this.handleColumnResize}
                            {...this.props}
                        >
                            { patientNameWithStatusColumn && 
                                <Column
                                columnKey="patientName"
                                fixed={true}
                                isResizable={true}
                                width={columnWidths.patientName}
                                header={<Cell style={columnHeaderStyle}>Patient Name</Cell>}
                                cell={({ rowIndex, data, columnKey, ...props }) =>
                                {
                                    return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                        <PatientWithStatusCell data={patients[rowIndex]}/>
                                    </Cell>
                                }
                            }
                            />}
                            {patientNameColumn &&
                                <Column
                                    columnKey="patientName"
                                    fixed={true}
                                    isResizable={true}
                                    width={columnWidths.patientName}
                                    header={<Cell style={columnHeaderStyle}>Patient Name</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <PatientCell data={patients[rowIndex]}/>
                                        </Cell>
                                    }
                                />
                            }
                            {
                                patientNewRecordColumn && <Column
                                columnKey="patientDetail"
                                fixed={true}
                                flexGrow={1}
                                isResizable={true}
                                width={columnWidths.patientDetail}
                                header={<Cell style={columnHeaderStyle}>Patient</Cell>}
                                cell={({ rowIndex, data, columnKey, ...props }) =>
                                    <Cell {...props}>
                                        <PatientNewRecordDataCell data={patients[rowIndex]} showNotes={this.onShowNotes} reengageIndicator={reengageIndicator}
                                        isCne={isCne} ce_flag={ce_flag} queue={queue}/>
                                    </Cell>
                                }
                            />
                            }
                            {patientDetailColumn &&
                                <Column
                                    columnKey="patientDetail"
                                    fixed={true}
                                    flexGrow={1}
                                    isResizable={true}
                                    width={columnWidths.patientDetail}
                                    header={<Cell style={columnHeaderStyle}>Patient</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <PatientDetailCell data={patients[rowIndex]} showNotes={this.onShowNotes} reengageIndicator={reengageIndicator}
                                            isCne={isCne} queue={queue}/>
                                        </Cell>
                                    }
                                />
                            }

                            {apcmprogramTypeColumn &&
                                <Column
                                    columnKey="apcmProgramDetail"
                                    fixed={true}
                                    flexGrow={1}
                                    isResizable={true}
                                    width={columnWidths.patientDetail}
                                    header={<Cell style={columnHeaderStyle}>Program</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <APCMProgramDetailCell data={patients[rowIndex]}/>
                                        </Cell>
                                    }
                                />
                            }

                            {addressColumn &&
                                <Column
                                    columnKey="address"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.address}
                                    header={<Cell style={columnHeaderStyle}>Address</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <AddressCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                />
                            }
                            {lastHistoryColumn &&
                                <Column
                                    columnKey="address"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.todoItem}
                                    header={<Cell style={columnHeaderStyle}>Last Note</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={this.popoverHoverFocus(patients[rowIndex].last_worked_note)}>
                                            <Cell {...props}>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: columnWidths.todoItem - 20,
                                                    }}
                                                >
                                                    {patients[rowIndex].last_worked_note}
                                                </div>
                                            </Cell>
                                        </OverlayTrigger>
                                    }
                                />
                            }
                            {phoneColumn &&
                                <Column
                                    columnKey="phone"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.phone}
                                    header={<Cell style={columnHeaderStyle}>Phone</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            {patients[rowIndex].phone}
                                        </Cell>
                                    }
                                />
                            }
                            {dobColumn &&
                                <Column
                                    columnKey="dob"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.dob}
                                    header={<Cell style={columnHeaderStyle}>DOB</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            {moment(patients[rowIndex].date_of_birth).format('MM/DD/YYYY')}
                                        </Cell>
                                    }
                                />
                            }
                            {providerDetailColumn &&
                                <Column
                                    columnKey="provider"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={200}
                                    header={<Cell style={columnHeaderStyle}>Provider</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>{
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            <ProviderDetailCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                    }
                                />
                            }
                             {programTypeColumn &&
                                <Column
                                    columnKey="program"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={200}
                                    header={<Cell style={columnHeaderStyle}>Program</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>{
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            <ProgramDetailCell data={patients[rowIndex]} queue={queue} />
                                        </Cell>
                                    }
                                    }
                                />
                            }
                            {
                                showSupportReason &&
                                <Column
                                    columnKey="supportReason"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={200}
                                    header={<Cell style={columnHeaderStyle}>Support Reason</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>{
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            {this.getReason(patients[rowIndex].support_reason_id)}
                                        </Cell>
                                    }
                                    }
                                />
                            }
                            {
                                 myPatientBillingColumn && <Column
                                 columnKey="timeBilled"
                                 fixed={false}
                                 isResizable={true}
                                 flexGrow={1}
                                 width={columnWidths.status}
                                 header={<Cell style={columnHeaderStyle}>Billing</Cell>}
                                 cell={({ rowIndex, data, columnKey, ...props }) => {
                                     return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                         <MyPatientBilledCell data={patients[rowIndex]} />
                                     </Cell>
                                 }
                                 }
                             />
                            }
                            {timeBilledColumn &&
                                <Column
                                    columnKey="timeBilled"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={125}
                                    header={<Cell style={columnHeaderStyle}>Time in Chart</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <TimeBilledCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                />
                            }
                            {queue !== 'records' && todoItemColumn &&
                                <Column
                                    columnKey="todoItemColumn"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.todoItem}
                                    header={<Cell style={columnHeaderStyle}>To Do Item</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={this.popoverHoverFocus(patients[rowIndex].urgent_description)}>
                                            <Cell {...props}>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: columnWidths.todoItem - 20,
                                                    }}
                                                >
                                                {patients[rowIndex].urgent_description}
                                                </div>
                                            </Cell>
                                            </OverlayTrigger>
                                    }
                                />
                            }
                            {recordInformation &&
                                <Column
                                    columnKey="recordInformation"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.todoItem}
                                    header={<Cell style={columnHeaderStyle}>Record Information</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) => <Cell {...props}>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: columnWidths.todoItem - 20,
                                                    }}
                                                >
                                                    <div style={{padding:5}}> Record Matched: {
                                                        patients[rowIndex].record_matched_date ? 
                                                            moment(patients[rowIndex].record_matched_date, 'YYYY-MM-DD HH:mm').format('hh:mm a MM/DD/YYYY') 
                                                        :"-"}</div>
                                                    <div style={{padding:5}}> Matched By: {
                                                        patients[rowIndex].record_matched_by ? 
                                                            patients[rowIndex].record_matched_by 
                                                        :"-"}
                                                    </div>
                                                </div>
                                            </Cell>
                                    }
                                />
                            }
                            {lastWorkedHistoryColumn &&
                                <Column
                                    columnKey="lastWorkedNote"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.todoItem}
                                    header={<Cell style={columnHeaderStyle}>Last Work History</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) => {
                                        return <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={this.popoverHoverFocus(patients[rowIndex].last_worked_note)}>
                                            <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: columnWidths.todoItem - 20,
                                                    }}
                                                >
                                                    {patients[rowIndex].last_worked_note}
                                                </div>
                                            </Cell>
                                        </OverlayTrigger>
                                    }
                                    }
                                />
                            }
                            {
                                myPatientStatusColumn && <Column
                                    columnKey="status"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={columnWidths.status}
                                    header={<Cell style={columnHeaderStyle}>Patient State</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) => {
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            <MyPatientStatusCell data={patients[rowIndex]} 
                                                urgentTypes={this.props.urgentTypes}/>
                                        </Cell>
                                    }
                                    }
                                />
                            }
                            {statusColumn &&
                                <Column
                                    columnKey="status"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={columnWidths.status}
                                    header={<Cell style={columnHeaderStyle}>Patient Status</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <StatusCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                />
                            }
                            {chartColumn &&
                                <Column
                                    columnKey="chart"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={columnWidths.chart}
                                    header={<Cell style={columnHeaderStyle}>Chart</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) => {
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            <div style={{ textAlign: 'center' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ padding: '2px 12px' }}
                                                    onClick={() => this.handlePatientSelect(patients[rowIndex])}
                                                >
                                                    Open Chart
                                            </button>
                                            {
                                                this.inAutoDialValue(patients[rowIndex]) && <div style={{color:'#f52b04'}}>
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    <span style={{fontSize:12,paddingLeft:2}}>In Autodial Queue</span>
                                                    </div>
                                            }
                                                {
                                                    lastWorkedByColumn && <LastWorkedCell data={patients[rowIndex]} />
                                                }

                                            </div>
                                        </Cell>
                                    }
                                    }
                                />
                            }

                            {openCloseChartColumn &&
                                <Column
                                    columnKey="openCloseChart"
                                    fixed={false}
                                    isResizable={true}
                                    flexGrow={1}
                                    width={columnWidths.chart}
                                    header={<Cell style={columnHeaderStyle}>Open/Force Close Chart</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ padding: '2px 12px', marginTop: '10px' }}
                                                onClick={() => this.handleRequestChartClosure(patients[rowIndex])}
                                            >
                                                Request Closure
                                        </button>
                                        </Cell>
                                    }
                                />
                            }
                            {actionsColumn &&
                                <Column
                                    columnKey="actions"
                                    isResizable={false}
                                    flexGrow={1}
                                    width={columnWidths.actions}
                                    header={<Cell style={columnHeaderStyle}>Actions</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <ActionCell
                                                autoDial={this.inAutoDialValue(patients[rowIndex])}
                                                patient={patients[rowIndex]}
                                                callLogCodes={callLogCodes}
                                                onPatientSelect={this.handlePatientSelect}
                                                onCreateQuickWorkItem={this.props.createQuickWorkItem}
                                            />
                                        </Cell>
                                    }
                                />
                            }
                            
                            {
                                <Column
                                    columnKey="contactPreference"
                                    fixed={false}
                                    isResizable={true}
                                    width={columnWidths.contactPreference}
                                    header={<Cell style={columnHeaderStyle}>Contact Preference</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) => {
                                        let scheduleColor = '#000000';
                                        let iconColor = '';
                                        let icon = '';
                                        let showScheduleCall = true;
                                        if(patients[rowIndex].call_scheduled_slot_id != 0){
                                            const scheduleTime =   moment(patients[rowIndex].call_scheduled_ts);
                                            const currentTime = moment(patients[rowIndex].current_est_time);
                                            if(scheduleTime.year() < currentTime.year() || 
                                            (scheduleTime.year() == currentTime.year() && 
                                             scheduleTime.month() < currentTime.month())){
                                                showScheduleCall = false;
                                            }else if(currentTime.isBetween(moment(scheduleTime).add(-1,'hours'),scheduleTime)){
                                                iconColor = 'rgb(52,117,35)';
                                                icon = 'fa fa-clock-o'
                                            }else if(currentTime.isBetween(scheduleTime,moment(scheduleTime).add(1,'hours'))){
                                                iconColor = 'rgb(224,165,95)';
                                                icon = 'fa fa-exclamation-triangle'
                                            }else if(currentTime.isAfter(moment(scheduleTime).add(1,'hours'))){
                                                scheduleColor = '#f52b04';
                                                iconColor = '#f52b04';
                                                icon = 'fa fa-times-circle'
                                            }
                                        }
                                        return (<OverlayTrigger trigger={['hover', 'focus']} placement='top'
                                                                overlay={this.popoverHoverFocus(patients[rowIndex].contact_preferences)}>
                                            <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: columnWidths.contactPreference - 40,
                                                    }}
                                                >
                                                    {
                                                        isMyPatientQueue && <div style={{display:'flex'}}>
                                                        <div style={{fontWeight: 600}}>
                                                        Time Zone:
                                                        </div>
                                                        <div style={{fontWeight: 400,paddingLeft:5}}>
                                                            {patients[rowIndex].local_time_zone}
                                                        </div>
                                                    </div>
                                                    }
                                                    
                                                    {
                                                        patients[rowIndex].call_scheduled_slot_id == 0 ?
                                                            <div>
                                                                <div style={{fontWeight: 600}}>
                                                                    Scheduled Call:
                                                                </div>
                                                                <div style={{fontWeight: 500}}>
                                                                    Patient has no call preference
                                                                </div>
                                                            </div>
                                                            : patients[rowIndex].call_scheduled_ts_patient_tz  && showScheduleCall &&
                                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                                <div style={{color:scheduleColor}}>
                                                                    <div style={{fontWeight: 600}}>
                                                                        Scheduled Call:
                                                                    </div>
                                                                    <div style={{fontWeight: 500}}>
                                                                        {moment(patients[rowIndex].call_scheduled_ts, 'YYYY-MM-DD HH:mm').format('hh:mm a MM/DD/YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        icon && <li className={icon} style={{color:iconColor,fontSize:20}}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                    }

                                                    {patients[rowIndex].contact_preferences ? patients[rowIndex].contact_preferences : " - "}
                                                    
                                                    {
                                                        patients[rowIndex].pcc_name && 
                                                        <div style={{display:'flex'}}>
                                                            <div style={{fontWeight: 600}}>
                                                            Coordinator:
                                                            </div>
                                                            <div style={{fontWeight: 400,paddingLeft:5}}>
                                                                {patients[rowIndex].pcc_name}
                                                            </div>
                                                        </div>
                                                    }
                                                     {
                                                        isMyPatientQueue && <div style={{display:'flex'}}>
                                                           <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                <div className="value-box" style={{ width: '100%' }}><span>ZIP Code:</span> {patients[rowIndex].zip_code || 'N/A'}</div>
                                                                <div className="value-box" style={{ width: '100%' }}><span>State:</span> {patients[rowIndex].state || 'N/A'}</div>
                                                            </div>
                                                            </div>
                                                     }
                                                    
                                                </div>
                                            </Cell>
                                        </OverlayTrigger>)
                                    }
                                    }
                                />
                            }
                            {statusActionsColumn &&
                                <Column
                                    columnKey="statusActionsColumn"
                                    isResizable={false}
                                    flexGrow={1}
                                    width={columnWidths.actions}
                                    header={<Cell style={columnHeaderStyle}>Actions</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <StatusActionsColumn
                                                onShowPhs={this.onShowPhs}
                                                patient={patients[rowIndex]}
                                                activeUser={activeUser}
                                                onPatientSelect={this.handlePatientSelect}
                                                onVerifyPatient={this.handleVerifyPatient}
                                                onGetChartOpenBy={this.props.getChartOpenBy}
                                                onShowOptOut={this.handleShowOptOut}
                                                onShowUnDecline={this.handleShowUndecline}
                                                isUserAdmin={isUserAdmin}
                                                requestChartClosure={() => this.handleRequestChartClosure(patients[rowIndex])}
                                            />
                                        </Cell>
                                    }
                                />
                            }

                            {/*{lastWorkedByColumn &&*/}
                            {/*<Column*/}
                            {/*columnKey="lastWorkedBy"*/}
                            {/*isResizable={true}*/}
                            {/*width={columnWidths.lastWorkedBy}*/}
                            {/*header={<Cell style={columnHeaderStyle}>Last Access</Cell>}*/}
                            {/*cell={({ rowIndex, data, columnKey, ...props }) =>*/}
                            {/*<Cell {...props}>*/}
                            {/*<LastWorkedCell data={patients[rowIndex]} />*/}
                            {/*</Cell>*/}
                            {/*}*/}
                            {/*/>*/}
                            {/*}*/}
                            {openedByColumn &&
                                <Column
                                    columnKey="openedByColumn"
                                    isResizable={false}
                                    flexGrow={1}
                                    width={columnWidths.actions}
                                    header={<Cell style={columnHeaderStyle}>Opened By</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <StatusActionsColumn
                                                onShowPhs={this.onShowPhs}
                                                patient={patients[rowIndex]}
                                                onPatientSelect={this.handlePatientSelect}
                                                onVerifyPatient={this.handleVerifyPatient}
                                                onGetChartOpenBy={this.props.getChartOpenBy}
                                                onShowOptOut={this.handleShowOptOut}
                                                onShowUnDecline={this.handleShowUndecline}
                                                isUserAdmin={isUserAdmin}
                                                requestChartClosure={() => this.handleRequestChartClosure(patients[rowIndex])}
                                            />
                                        </Cell>
                                    }
                                />
                            }
                            {lastContactColumn &&
                                <Column
                                    columnKey="lastContact"
                                    isResizable={true}
                                    width={columnWidths.lastContact}
                                    header={<Cell style={columnHeaderStyle}>Last Contact</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>{
                                        return <Cell {...props} style={this.getMyPatientStatusColor(patients[rowIndex])}>
                                            <LastContactCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                }
                                />
                            }
                            {
                                callAttemptsCol &&
                                <Column
                                    columnKey={queue === "unverified" || queue === "rpmenrollment" ? "callCount" : "call_count"}
                                    isResizable={true}
                                    width={columnWidths.callAttempts}
                                    header={
                                        !ce_flag ? <Cell style={columnHeaderStyle}>Attempts</Cell>
                                        :
                                        <SortHeaderCell
                                            onSortChange={this._onSortChange.bind(this)}
                                            sortDir={queue === "unverified" || queue === "rpmenrollment" ? colSortDirs.callCount : colSortDirs.call_count}
                                            columnHeaderStyle={columnHeaderStyle}>
                                            Attempts
                                        </SortHeaderCell>
                                    }
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {!ce_flag ? (patients[rowIndex].call_attempts ? patients[rowIndex].call_attempts : " - " ):
                                                ( patients[rowIndex].call_count ? patients[rowIndex].call_count : " - ")}
                                            </div>
                                        </Cell>
                                    }
                                />

                            }
                            {lastOutCallColumn &&
                                <Column
                                    columnKey={queue === "unverified" || queue === "rpmenrollment" ? "lastOutCallTs" : "last_out_call_ts"}
                                    isResizable={true}
                                    width={columnWidths.lastOutCall}
                                    header={
                                        <SortHeaderCell
                                            onSortChange={this._onSortChange.bind(this)}
                                            sortDir={queue === "unverified" || queue === "rpmenrollment" ? colSortDirs.lastOutCallTs : colSortDirs.last_out_call_ts}
                                            columnHeaderStyle={columnHeaderStyle}>
                                            Last Called
                                    </SortHeaderCell>
                                    }
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <LastCalledCell data={patients[rowIndex]} />
                                        </Cell>
                                    }
                                />
                            }
                        </ResponsiveFixedDataTable>
                        <PaginationBar
                            visibleBtns={5}
                            pageCount={meta.total_pages}
                            currentPage={meta.page + 1}
                            onPageSelected={(pageNum) =>
                                this.props.fetchQueue(queue, endpoint, pageNum - 1, pageSize, orgId, locationId, providerId, showFilters,
                                    showSort, sortBy, showProgramTypeFilter, programType,showScheduleCallFilter,scheduledCallStatus,
                                    gapsEngagementStatus,preferredLanguage,selectedTeam,selectedClinician,callAttempts,completedChecked,urgentType, reengageFilter ? reEngageFiltering : '',
                                    '',selectedTeamForAllPateints,selectedClinicianForAllPatients,
                                    selectedGeographics,selectedTimezones,allPatientsCallAttempts,connectedCall,showOnlyCallAttempts,showUrgentFilter, apcmEnrollment)
                            }
                        />
                        <SelectPatientConfirmPopup
                            data={this.state.selectedPatient}
                            show={this.state.showPatientPopup}
                            onPopupHide={() => this.setState({ showPatientPopup: false })}
                        />
                        <OptOutModal
                            patient={this.state.selectedPatient}
                            show={this.state.showOptOut}
                            onHide={() => this.setState({ showOptOut: false })}
                            onConfirm={this.handleOptOut}
                            declineText={declineButtonText}
                        />
                        <UnDeclineModal
                            patient={this.state.selectedPatient}
                            show={this.state.showUnDecline}
                            onHide={() => this.setState({ showUnDecline: false })}
                            onConfirm={this.handleUnDecline}
                        />
                        <PatientChartClosureRequestedDataModal
                            patient={this.state.requestedClosurePatientId}
                            show={this.state.showPatientDataModal}
                            onHide={() => this.setState({ showPatientDataModal: false })}
                            loadNoteNeeded={loadNoteNeeded}
                        />
                        {
                            this.state.notes !== null &&   <PatientNotesPopup notes={this.state.notes} onHide={() => this.setState({notes:null})}/>
                        }
                      
                    </div>
                </Panel>
            </div>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        patients: state.queue[ownProps.queue],
        meta: state.queue[`${ownProps.queue}Meta`],
        currentQueue: state.queue.currentQueue,
        organizations: state.queue.organizations,
        orgId: state.queue.orgId,
        locations: state.queue.locations,
        locationId: state.queue.locationId,
        providers: state.queue.providers,
        providerId: state.queue.providerId,
        sortBy: state.queue[`${ownProps.queue}SortBy`],
        callLogCodes: state.queue.callLogCodes,
        searchTerm: state.queue.searchTerm,
        searchDate: state.queue.searchDate,
        searchPhone: state.queue.searchPhone,
        noSearchResults: state.queue.noSearchResults,
        user: state.auth,
        activeUser: state.admin.activeUser,
        teams:state.admin.teams,
        selectedTeam:state.queue.selectedTeam,
        selectedTeamForAllPateints: state.queue.selectedTeamForAllPateints,
        clinicians:state.admin.clinicians,
        allClinicians: state.admin.allClinicians,
        selectedClinician:state.queue.selectedClinician,
        selectedClinicianForAllPatients: state.queue.selectedClinicianForAllPatients,
        selectedGeographics: state.queue.selectedGeographics,
        selectedTimezones:state.queue.selectedTimezones,
        allPatientsCallAttempts:state.queue.allPatientsCallAttempts,
        phsPdfUrl: state.levelTwoDashboard.phsPdfUrl,
        chartClosureRequested: state.admin.chartClosureRequested,
        router: state.router,
        programType: state.queue.programType,
        scheduledCallStatus:state.queue.scheduledCallStatus,
        gapsEngagementStatus:state.queue.gapsEngagementStatus,
        preferredLanguage:state.queue.preferredLanguage,
        callAttempts:state.queue.callAttempts,
        completedChecked: state.queue.completedChecked,
        apcmEnrollment: state.queue.apcmEnrollment,
        selectedEligibilityLevel: state.queue.selectedEligibilityLevel,
        showOnlyCallAttempts: state.queue.showOnlyCallAttempts,
        counts: state.queue.counts,
        urgentTypes: state.app.urgentTypes,
        urgentType: state.queue.urgentType,
        reEngageFiltering: state.queue.reEngageFiltering,
        clinicalServiceGoal: state.queue.clinicalServiceGoal,
        connectedCall: state.queue.connectedCall,
        userId: state.auth.id,
        patientServicesReasons: state.admin.patientServicesReasons
    }),
    {
        fetchQueue,
        resetSearch,
        fetchLocations,
        searchPatients,
        createQuickWorkItem,
        getChartOpenBy,
        checkChartOpenBy,
        verifyPatient,
        getPHSPdf,
        redirectToRecords,
        unDeclinePatient,
        refetchSearchPatients,
        openEventStream,
        requestChartClosure,
        loadPatientDetail,
        checkIfChartClosed,
        checkOpenAssociatedChart,
        hideToast,
        showToast,
        resetSearchResult,
        getPatientNotes,
        getClinicians,
        getAllClinicians,
        getMyQueueCounts,
        getMyTeamQueueCounts,
        getAllPatientsQueueCounts,
        getUrgentTodoTypes,
        getPatientServicesReasons
    }
)(PatientQueue);
