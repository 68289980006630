import React from 'react';
import _ from "lodash"

const APCMProgramDetailCell = ({data}) => {
       

        return <div>
                <div style={{fontWeight:600}}>
                       APCM
                </div>
                {
                        <div className="value-box" style={{ width: "100%", display:'flex', flex:1, flexDirection:'column', padding:0}}>
                               
                                <div style={{display:'flex',justifyContent:'flex-start', padding:0}}>
                                        <span style={{fontWeight:400}}>APCM Level:</span>
                                        <span style={{paddingLeft:15}}>{data.apcm_eligible_level ? data.apcm_eligible_level : '-'}</span>
                                </div>
                               
                        
                    </div> 

                }
                
               
        </div>
};

export default APCMProgramDetailCell;
