import React from 'react';
import reactMixin from 'react-mixin';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import {Modal, Button, FormControl, FormGroup, ControlLabel} from 'react-bootstrap';
import {StateData} from '../../constants/other'
import _ from 'lodash';
import moment from "moment";
import FormInput from "../../components/FormInput";
import ScheduleNextCallModal from "./ScheduleNextCallModal"

class ConfirmationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            callType: (this.props.patient.consent_type !== 'INBOUND' 
                && this.props.patient.consent_type !== 'OUTBOUND' 
                && this.props.patient.consent_type !== 'SELF_ENROLLED'
                && this.props.patient.consent_type !== 'VIRTUAL_ASSISTANT_ENROLLED') 
                ? '' : this.props.patient.consent_type,
            address1: this.props.patient.address1,
            address2: this.props.patient.address2,
            city: this.props.patient.city,
            state: this.props.patient.state || '',
            zip_code: this.props.patient.zip_code,
            okToText: props.patient.ok_to_text != null ? props.patient.ok_to_text : null,
            step: 1
        }
        this.inputMobilePhn = props.patient.mobile ? props.patient.mobile : ''
    }


    componentWillReceiveProps(nextProps) {
        this.inputMobilePhn = nextProps.patient.mobile ? nextProps.patient.mobile : '';
        if (this.state.step == 1) {
            this.setState({
                address1: nextProps.patient.address1,
                address2: nextProps.patient.address2,
                city: nextProps.patient.city,
                state: nextProps.patient.state || '',
                zip_code: nextProps.patient.zip_code,
                callType: (nextProps.patient.consent_type !== 'INBOUND' 
                    && nextProps.patient.consent_type !== 'OUTBOUND' 
                    && nextProps.patient.consent_type !== 'SELF_ENROLLED'
                    && nextProps.patient.consent_type !== 'VIRTUAL_ASSISTANT_ENROLLED') 
                    ? '' : nextProps.patient.consent_type,
                okToText: nextProps.patient.ok_to_text != null ? nextProps.patient.ok_to_text : null,
            });

        }
    }

    componentWillUnmount() {
        this.setState({step: 1})
    }

    onConfirm() {
        let {callType, address1, address2, city, state, zip_code, okToText, step} = this.state;
        const {isCne} = this.props;
        if (isCne && step == 1) {
            this.setState({step: 2})
            return;
        }
        const okToTextInt = okToText != null ? parseInt(okToText + '') : null;
        // TODO: should this be an empty string or set to null?
        const mobileNo = (okToTextInt === 1) && this.refs['inputMobilePhn'] ? this.refs['inputMobilePhn'].getValue() : '';

        this.props.onConfirm(address1, address2, city, state, zip_code, callType, okToTextInt, mobileNo);
    }

    onOkTextChanged(e) {
        const value = e.target.value;
        if (value == 1) {
            this.setState({
                requiredPhoneNum: true
            })
        } else {
            this.setState({
                requiredPhoneNum: false,
                mobileNo: ''
            })
        }
        this.setState({okToText: e.target.value});
    }

    onMobileNoChanged(e) {
        this.setState({mobileNo: e.target.value});
    }

    onClosePopup() {
        this.setState({step: 1});
        this.props.onHide();
    }

    render() {
        let {
            show, onHide, patient, confirmText, isCne, onScheduleSlotSelected,
            onScheduleDateSelected, scheduleNextCallSlot, scheduleNextCallDate, isRPM, isClinical
        } = this.props;
        const {callType, step} = this.state;
        const textClinical = this.props.patient.provider.text_clinical;
        let confirmDisabled = true;
        let buttonText = confirmText;
        if (isCne || isRPM) {
            if (callType) {
                confirmDisabled = false;
            }
        } else {
            confirmDisabled = false;
        }

        if (isCne && step == 1) {
            buttonText = 'Next'
        }

        if (!show) {
            return <div/>
        }

        return (
            <Modal show={show} onHide={this.onClosePopup.bind(this)} bsSize={step == 2 ? "large" : ""}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm {confirmText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        step == 2 ? <ScheduleNextCallModal
                                isClinical={isClinical}
                                isCne={isCne}
                                onScheduleDateSelected={onScheduleDateSelected}
                                onScheduleSlotSelected={onScheduleSlotSelected}
                                scheduleNextCallSlot={scheduleNextCallSlot}
                                scheduleNextCallDate={scheduleNextCallDate}/>
                            :
                            <div>
                                {
                                    (isCne || isRPM) && <div>
                                    <ControlLabel>Please select whether this consent was from inbound or outbound
                                        call.</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        valueLink={this.linkState('callType')}
                                        placeholder="Please select call type"
                                        style={{marginBottom: '10px'}}
                                    >
                                        <option value='' selected disabled>Select an option</option>
                                        <option value={'INBOUND'}>Inbound</option>
                                        <option value={'OUTBOUND'}>Outbound</option>
                                        <option value={'SELF_ENROLLED'}>Self-Enrolled</option>
                                        <option value={'VIRTUAL_ASSISTANT_ENROLLED'}>Virtual Assistant-Enrolled</option>
                                        
                                        
                                    </FormControl>
                                </div>
                                }

                                {(isCne || isRPM) && callType &&

                                <div>
                                    {
                                        !isRPM && (
                                        textClinical ? 
                                        <div>
                                            <p>
                                                <strong>Program:</strong>
                                                <span style={{paddingLeft:15}}><strong>
                                                    {this.props.patient.program_type === 'apcm' ? `APCM ${this.props.patient.apcm_eligible_level ? 'Level ' + this.props.patient.apcm_eligible_level : ''}`: 'CCM'}
                                                    </strong></span>
                                            </p>
                                            <p>
                                                <strong>Confirm Ok to Text:</strong>
                                            </p>
                                            <div style={{display: 'flex'}}>
                                                <p>
                                                    <strong>Ok to Text:</strong>
                                                </p>
                                                <div
                                                    style={{display: 'flex', flexDirection: 'column', paddingLeft: 15}}>
                                                    <div style={{display: 'flex'}}>
                                                        <input type="radio" name="okToText"
                                                               style={{height: '15px !important'}}
                                                               value={1}
                                                            // disabled={!this.state.mobileNo}
                                                               checked={this.state.okToText == 1}
                                                               onChange={this.onOkTextChanged.bind(this)}/>
                                                        <div style={{marginLeft: 15}}>
                                                            Patient consents to being contacted via text message at
                                                            mobile phone.
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex'}}>
                                                        <input type="radio" name="okToText"
                                                               style={{height: '15px !important'}}
                                                               value={0}
                                                               checked={this.state.okToText == 0}
                                                               onChange={this.onOkTextChanged.bind(this)}/>
                                                        <div style={{marginLeft: 15}}>
                                                            Patient <span
                                                            style={{fontWeight: 'bold'}}>DOES NOT</span> consent to
                                                            being contacted via text message.
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex'}}>
                                                        <input type="radio" name="okToText"
                                                               style={{height: '15px !important'}}
                                                               value={2}
                                                               checked={this.state.okToText == 2}
                                                               onChange={this.onOkTextChanged.bind(this)}/>
                                                        <div style={{marginLeft: 15}}>
                                                            No - Patient <span style={{fontWeight: 'bold'}}>does not have access</span> to
                                                            text message.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.okToText == 1 && <div>
                                                    <div className="form-group">
                                                        <label className="col-md-3 control-label text-left">Mobile
                                                            Phone:</label>
                                                        <div className="col-md-9 remove-side-padding">
                                                            <FormInput type="phone"
                                                                       isRequired={!this.state.requiredPhoneNum}
                                                                       ref="inputMobilePhn"
                                                                       placeholder="(XXX) XXX-XXXX"
                                                                // handleBlurEvent={this.handlePhoneRequired.bind(this)}
                                                                       initialValue={this.inputMobilePhn}
                                                                       maxLength="25"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <p>
                                                <strong>Confirm Ok to Text:</strong>
                                            </p>
                                            <div style={{color:'red'}}>
                                                *Clinical Text disabled for Organization
                                            </div>    
                                        </div>
                                        )
                                    }


                                    <p style={{marginTop: 15}}>
                                        <strong>Confirm the following mailing address:</strong>
                                    </p>
                                    <FormGroup>
                                        <ControlLabel>Address (Line 1)</ControlLabel>
                                        <FormControl
                                            type="text"
                                            valueLink={this.linkState('address1')}
                                            placeholder="Address (Line 1)"
                                            style={{marginBottom: '10px'}}
                                        />
                                        <ControlLabel>Address (Line 2)</ControlLabel>
                                        <FormControl
                                            type="text"
                                            valueLink={this.linkState('address2')}
                                            placeholder="Address (Line 2)"
                                            style={{marginBottom: '10px'}}
                                        />
                                        <ControlLabel>City</ControlLabel>
                                        <FormControl
                                            type="text"
                                            valueLink={this.linkState('city')}
                                            placeholder="City"
                                            style={{marginBottom: '10px'}}
                                        />
                                        <ControlLabel>State</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            valueLink={this.linkState('state')}
                                            placeholder="State"
                                            style={{marginBottom: '10px'}}
                                        >
                                            <option value='' disabled>-------------------</option>
                                            {StateData.map((state) => {
                                                return (
                                                    <option value={state.id} key={state.id}>{state.name}</option>
                                                )
                                            })}
                                        </FormControl>
                                        <ControlLabel>Zip Code</ControlLabel>
                                        <FormControl
                                            type="text"
                                            valueLink={this.linkState('zip_code')}
                                            placeholder="Zip Code"
                                            style={{marginBottom: '10px'}}
                                        />
                                    </FormGroup>
                                </div>
                                }
                                {
                                    (isCne || isRPM) && callType ? <p style={{paddingTop: 10}}>
                                            Clicking "{buttonText}"
                                            will {confirmText} {patient.first_name} {patient.last_name} into the program. If
                                            you would not like to {confirmText} this patient, please
                                            click "Cancel."
                                        </p>
                                        : !isCne ?
                                        <p style={{paddingTop: 10}}>
                                            Clicking "{confirmText}"
                                            will {confirmText} {patient.first_name} {patient.last_name} into the
                                            program. If you would not like to {confirmText} this patient, please
                                            click "Cancel."
                                        </p>
                                        : ''

                                }
                            </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={this.onClosePopup.bind(this)}>
                        Cancel
                    </Button>
                    <Button bsStyle="success" onClick={this.onConfirm.bind(this)}
                            disabled={confirmDisabled}>
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

reactMixin(ConfirmationModal.prototype, LinkedStateMixin);

export default ConfirmationModal;
