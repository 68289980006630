import React from 'react';
import PatientQueue from './PatientQueue';

const APCMUnverifiedQueue = () =>
    <PatientQueue
	    showQueueCount
	    showFilters
		showScheduleCallFilter
        patientDetailColumn
		apcmprogramTypeColumn
        providerDetailColumn
        actionsColumn
        page
        lastOutCallColumn
        lastHistoryColumn
		callAttemptsCol
		reengageFilter
		reengageIndicator
		showEligibilityFilter
        queue="apcmunverified"
        endpoint="Unverified"
        label="APCM Unverified"
	    showSort
	    sortOptions={[
		    {
		    	'name': 'Last Called Ascending',
			    'key': 'lastOutCallTs&dir=asc',
		    },
		    {
			    'name': 'Last Called Descending',
			    'key': 'lastOutCallTs&dir=desc',
		    }
	    ]}
    />;

export default APCMUnverifiedQueue;
