import _ from 'lodash';
import { uniqByKey } from '../utils';
import CONSTANTS from '../constants';

const {
    FETCH_LOCATIONS_SUCCESS,
    FETCH_CALL_LOG_CODES_SUCCESS,
    FETCH_ORGS_SUCCESS,
    FETCH_QUEUE_REQUEST,
    FETCH_QUEUE_SUCCESS,
    FETCH_QUEUE_FAILURE,
    SEARCH_PATIENTS_SUCCESS,
    SEARCH_PATIENTS_FAILURE, 
    RESET_SEARCH_RESULE,
    ACTIVE_USER_RECEIVED,
    ON_TEAM_SELECTED,
    ON_CLINICIAN_SELECTED,
    MYQUEUE_COUNTS_RECEIVED,
    ALLPATIENTS_COUNTS_RECEIVED

} = CONSTANTS;

const initialState = {
    organizations: [],
    locations: [],
    providers: [],
    callLogCodes: [],
    pageSize: 15,
    orgId: null,
    locationId: null,
    providerId: null,
    showFilters: false,
	showSort: false,
	sortBy: null,
    currentQueue: 'search',
    rpmenrollment:[],
    rpmenrollmentMeta: {},
    unverified: [],
    unverifiedMeta: {},
    apcmunverified: [],
    apcmunverifiedMeta: {},
    unverifiedUnder65: [],
    unverifiedUnder65Meta: {},
    new: [],
    newMeta: {},
    active: [],
    strokecare:[],
    activeMeta: {},
    gAPSColorectal:[],
    gAPSColorectalMeta:{},
    gAPSBreastCancer:[],
    gAPSBreastCancerMeta:{},
    gAPSDiabeticEye:[],
    gAPSDiabeticEyeMeta:{},
    strokecareMeta: {},
	patientServices: [],
	patientServicesMeta: {},
	patientServicesOpenCharts: [],
	patientServicesOpenChartsMeta: {},
	urgentNoAnswer: [],
	urgentNoAnswerMeta: {},
    Optout: [],
    OptoutMeta: {},
    urgentAccount: [],
    urgentAccountMeta: {},
    urgentCcm: [],
    urgentCcmMeta: {},
    urgentRefill: [],
    urgentTriage:[],
    urgentRefillMeta: {},
    urgentTriageMeta:{},
	urgentCarePlan: [],
	urgentCarePlanMeta: {},
	urgentAppointment: [],
	urgentAppointmentMeta: {},
	urgentPreEnrollment: [],
	urgentPreEnrollmentMeta: {},
	urgentMips: [],
	urgentMipsMeta: {},
    urgentNurse: [],
    urgentNurseMeta: {},
    urgentITMedicalRecord:[],
    urgentITMedicalRecordMeta:{},
    urgentDischargeManagement:[],
    urgentDischargeManagementMeta:{},
    urgentCne: [],
    urgentCneMeta: {},
	urgentBadPhone: [],
	urgentBadPhoneMeta: {},
	urgentPoa: [],
	urgentPoaMeta: {},
	urgentRequestedInfo: [],
	urgentRequestedInfoMeta: {},
	urgentCallBack: [],
	urgentCallBackMeta: {},
	urgentRoundTwo: [],
	urgentRoundTwoMeta: {},
	urgentCopay: [],
	urgentCopayMeta: {},
	urgentDoNotCall: [],
	urgentDoNotCallMeta: {},
	urgentRemoveFromQueue: [],
	urgentRemoveFromQueueMeta: {},
	urgentProviderChange: [],
	urgentProviderChangeMeta: {},
	urgentLead: [],
	urgentLeadMeta: {},
	urgentSupport: [],
	urgentSupportMeta: {},
    records: [],
    recordsMeta: {},
    search: [],
    searchMeta: {},
    searchTerm: '',
    searchDate: '',
    noSearchResults: false,
    programType:'',
    gapsEngagementStatus:'All',
    preferredLanguage:'English',
    selectedTeam:'',
    selectedClinician:'',
    selectedTeamForAllPateints:[],
    selectedClinicianForAllPatients:[],
    selectedGeographics:[],
    selectedTimezones:[],
    allPatientsCallAttempts:[],
    myPatients: [],
	myPatientsMeta: {},
    myTeamPatients: [],
	myTeamPatientsMeta: {},
    allPatients: [],
	allPatientsMeta: {},
    completedChecked:true,
    showOnlyCallAttempts:false,
    reEngageFiltering:'NewUnverified',
    clinicalServiceGoal:'',
    apcmEnrollment: null,
    selectedEligibilityLevel:''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ORGS_SUCCESS:
            return {
                ...state,
                organizations: action.payload,
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: _.sortBy(uniqByKey(action.payload.location, 'id'), 'name'),
            };

        case FETCH_QUEUE_REQUEST:
            return {
                ...state,
                noSearchResults: false,
            };
        case MYQUEUE_COUNTS_RECEIVED:{
            return {
                ...state,
                counts: action.payload.counts,
            };
        }    
        case ALLPATIENTS_COUNTS_RECEIVED: {
            return {
                ...state,
                counts: action.payload.counts,
            };
        }
        case FETCH_QUEUE_SUCCESS: {
            const { items, count, page, total_items, total_pages } = action.payload;
            const meta = { count, page, total_items, total_pages };
            const locationArray = _.filter(state.locations,(location) => {
                return location.id == action.locationId
            });
            const providers = locationArray.length >0 ? locationArray[0].provider : [];
            return {
                ...state,
                [action.queue]: items,
                [`${action.queue}Meta`]: meta,
                currentQueue: action.queue,
                orgId: action.orgId,
                programType: action.programType,
                scheduledCallStatus:action.scheduledCallStatus,
                preferredLanguage: action.preferredLanguage,
                gapsEngagementStatus: action.gapsEngagementStatus,
                locationId: action.locationId,
                providerId: action.providerId,
                providers: _.sortBy(providers, 'name'),
                locations: action.orgId ? state.locations : [],
                showFilters: action.showFilters,
	            showSort: action.showSort,
                selectedTeam:action.queue !== 'allPatients' ? action.selectedTeam : state.selectedTeam,
                selectedTeamForAllPateints: action.queue === 'allPatients' ? action.selectedTeamForAllPateints : state.selectedTeamForAllPateints,
                selectedClinician: action.queue === 'allPatients' ?  state.selectedClinician : action.selectedTeam == 1 && 
                    (action.queue !== 'myTeamPatients') ? '' : action.selectedClinician,
                selectedClinicianForAllPatients: action.queue === 'allPatients' ? action.selectedClinicianForAllPatients : state.selectedClinicianForAllPatients,
                selectedGeographics: action.queue === 'allPatients' ? action.selectedGeographics : state.selectedGeographics,
                selectedTimezones: action.queue === 'allPatients' ? action.selectedTimezones : state.selectedTimezones,
                allPatientsCallAttempts: action.queue === 'allPatients' ? action.allPatientsCallAttempts : state.allPatientsCallAttempts,
                callAttempts:action.callAttempts,
                completedChecked: action.completedChecked,
                showOnlyCallAttempts: action.showOnlyCallAttempts,
	            [`${action.queue}SortBy`]: action.sortBy,
                urgentType:action.urgentType,
                reEngageFiltering: action.queue !== 'unverified'  ? state.reEngageFiltering : action.reEngageFiltering,
                clinicalServiceGoal: action.queue !== 'allPatients'  ? state.clinicalServiceGoal : action.clinicalServiceGoal,
                connectedCall: action.queue !== 'allPatients' ? state.connectedCall : action.connectedCall,
                apcmEnrollment: action.apcmEnrollment,
                selectedEligibilityLevel: action.selectedEligibilityLevel
            };
        }
        case FETCH_QUEUE_FAILURE: {
            const locationIndex = state.locations.map((l) => l.id).indexOf(action.locationId);
            const providers = state.locations[locationIndex] ? state.locations[locationIndex].provider : [];
            return {
                ...state,
                [action.queue]: [],
                [`${action.queue}Meta`]: {},
                currentQueue: action.queue,
                orgId: action.orgId,
                programType: action.programType,
                scheduledCallStatus:action.scheduledCallStatus,
                preferredLanguage: action.preferredLanguage,
                locationId: action.locationId,
                providerId: action.providerId,
                providers: _.sortBy(providers, 'name'),
                locations: action.orgId ? state.locations : [],
                showFilters: action.showFilters,
	            showSort: action.showSort,
                selectedTeam:action.queue !== 'allPatients' ? action.selectedTeam : state.selectedTeam,
                selectedTeamForAllPateints:action.queue === 'allPatients' ? action.selectedTeam 
                    : state.selectedTeamForAllPateints,
                selectedClinician: state.queue !== 'allPatients' ? action.selectedClinician : state.selectedClinician,
                selectedClinicianForAllPatients:action.queue === 'allPatients' ? action.selectedClinician 
                    : state.selectedClinicianForAllPatients,
                selectedGeographics:action.queue === 'allPatients' ? action.selectedGeographics 
                    : state.selectedGeographics,  
                selectedTimezones:action.queue === 'allPatients' ? action.selectedTimezones 
                    : state.selectedTimezones,          
                allPatientsCallAttempts:action.queue === 'allPatients' ? action.allPatientsCallAttempts 
                    : state.allPatientsCallAttempts,         
                callAttempts:action.callAttempts,
                completedChecked: action.completedChecked,
                showOnlyCallAttempts: action.showOnlyCallAttempts,
	            [`${action.queue}SortBy`]: action.sortBy,
                urgentType:action.urgentType,
                reEngageFiltering: action.queue !== 'unverified'  ? state.reEngageFiltering : action.reEngageFiltering,
                clinicalServiceGoal: action.queue !== 'allPatients'  ? state.clinicalServiceGoal : action.clinicalServiceGoal,
                connectedCall: action.queue !== 'allPatients' ? state.connectedCall : action.connectedCall,
                apcmEnrollment: action.apcmEnrollment,
                selectedEligibilityLevel: action.selectedEligibilityLevel
            };
        }
        case FETCH_CALL_LOG_CODES_SUCCESS:
            return {
                ...state,
                callLogCodes: action.payload,
            };
        case SEARCH_PATIENTS_SUCCESS: {
            return {
                ...state,
                search: action.payload,
                noSearchResults: action.payload.length ? false : true,
                searchTerm: action.searchTerm,
                searchDate: action.searchDate,
            };
        }
        case SEARCH_PATIENTS_FAILURE:
            return {
                ...state,
                search: [],
                noSearchResults: true,
                searchTerm: action.searchTerm,
                searchDate: action.searchDate,
            };
        case RESET_SEARCH_RESULE:
            return {
                ...state,
                search: [],
                noSearchResults: false,
                searchTerm: action.searchTerm,
                searchDate: action.searchDate,
            };
        case ACTIVE_USER_RECEIVED:
            return {
                ...state,
                selectedTeam: action.payload.team_id,
                selectedClinician:action.payload.id
            };
        case ON_TEAM_SELECTED:   
            return {
                ...state,
                selectedTeam: state.queue !== 'allPatients' ? action.payload : '',
                selectedTeamForAllPateints: action.payload,
                selectedClinician:''
            }; 
        case ON_CLINICIAN_SELECTED:   
            return {
                ...state,
                selectedClinician:  state.queue !== 'allPatients' ?action.payload : '',
                selectedClinicianForAllPatients: state.queue === 'allPatients' ? action.payload : '',
            }; 
        default:
            return state;
     }
}
